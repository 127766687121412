import { Link } from "react-router-dom";
import Modal from "./Modal";
import settings from "../../config";

import { ReactComponent as Close } from "../../img/icons/close.svg";

import { ReactComponent as DiscordCircleIcon } from "../../img/icons/discord-circle.svg";
import { ReactComponent as PhoneCircleIcon } from "../../img/icons/phone-circle.svg";
import { ReactComponent as TelegramCircleIcon } from "../../img/icons/telegram-circle.svg";

const NeedHelpModal = ({ open, onClose }) => {
  return (
    <Modal isOpen={open} onClose={() => onClose(false)}>
      <Modal.Body header={false}>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="fs-3">Need Help?</h3>
          <button className="modal-close" onClick={() => onClose(false)}>
            <Close />
          </button>
        </div>
        <div className="d-flex flex-column gap-4 support-users mt-4 mb-4">
          <div className="d-flex flex-row gap-md-3 gap-2 user align-items-center">
            <img
              className="rounded-circle"
              src={require("../../img/jack-tanner-avatar.png")}
              width={80}
              height={80}
              alt="Contact Jack Tanner"
            />
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center gap-2">
                <h5>Jack Tanner</h5>
                <div>
                  <a
                    href="https://discordapp.com/users/574914463141068820"
                    target="_blank"
                  >
                    <DiscordCircleIcon
                      width="24"
                      height="24"
                      className="ms-2"
                    />
                  </a>
                  <a href="https://t.me/theblockstalk" target="_blank">
                    <TelegramCircleIcon
                      width="24"
                      height="24"
                      className="ms-2"
                    />
                  </a>
                </div>
              </div>
              <div className="mt-1">
                <span className="badge badge-success fw-normal text-dark">
                  Chief Executive Officer
                </span>
                <div className="d-flex align-items-center gap-1 mt-1">
                  <PhoneCircleIcon
                    width="26"
                    height="26"
                    className="text-muted"
                  />
                  <Link className="text-muted" to="tel:+31622165433">
                    +31 6 2216 5433
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row gap-md-3 gap-2 user align-items-center">
            <img
              className="rounded-circle"
              src={require("../../img/phil-patterson-avatar.jpeg")}
              width={80}
              height={80}
              alt="Contact Philip Patterson"
            />
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center gap-2">
                <h5>Philip Patterson</h5>
                <div>
                  <a
                    href="https://discordapp.com/users/1009886278898159626"
                    target="_blank"
                  >
                    <DiscordCircleIcon
                      width="24"
                      height="24"
                      className="ms-2"
                    />
                  </a>
                  <a href="https://t.me/philjames8" target="_blank">
                    <TelegramCircleIcon
                      width="24"
                      height="24"
                      className="ms-2"
                    />
                  </a>
                </div>
              </div>
              <div className="mt-1">
                <span className="badge badge-purple fw-normal text-dark">
                  Pangea Fundraising Lead
                </span>
                <div className="d-flex align-items-center gap-1 mt-1">
                  <PhoneCircleIcon
                    width="26"
                    height="26"
                    className="text-muted"
                  />
                  <Link className="text-muted" to="tel:+447828699027">
                    +44 7 8286 99027
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="support-links d-flex gap-3">
          <Link to={settings.config.helpUrl}>Send us a message</Link>
          <Link to="https://github.com/Tonomy-Foundation/documentation/blob/master/sales/LEOS%20Currency%20Purchasing%20Guide.pdf?raw=true">
            LEOS Purchase Guide
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NeedHelpModal;
