import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Metamask from "./Metamask";
import Modal from "../__Shared/Modal";
import getAuthorizedApi from "../../providers/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import { MetaMaskUIProvider } from "@metamask/sdk-react-ui";
import settings from "../../config";

import { ReactComponent as Info } from "../../img/icons/info-danger.svg";
import { ReactComponent as CheckBlue } from "../../img/icons/check-blue.svg";
import { ReactComponent as ErrorX } from "../../img/icons/error-x.svg";
import MetamaskIcon from "../../img/metamask-icon.png";
import NeedHelpModal from "../__Shared/NeedHelpModal";

export default function PaymentStep() {
  const [paymentType, setPaymentType] = useState(undefined);
  const navigation = useNavigate();

  const { user } = useContext(AuthContext);

  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [error, setError] = useState(null);

  const purchaseStr = localStorage.getItem("purchase");
  const purchase = purchaseStr ? JSON.parse(purchaseStr) : null;

  const [purchaseDetails, setPurchaseDetails] = useState(null);

  const lAmount = purchase?.amount;
  const lLeoAmount = purchase?.leoAmount;
  const lCurrency = purchase?.currency;

  const [amount, setAmount] = useState(lAmount);
  const [leoAmount, setLeoAmount] = useState(lLeoAmount);
  const [currency, setCurrency] = useState(lCurrency);

  const [showHelp, setShowHelp] = useState(false);

  const [showPaymentLink, setShowPaymentLink] = useState(false);
  const [deposit, setDeposit] = useState(null);
  const [checkB2BinPayPayment, setCheckB2BinPayPayment] = useState(false);
  const [showRepayment, setShowRepayment] = useState(false);
  const intervalIdRef = useRef(null);
  const timeoutIdRef = useRef(null);
  const [receivedAmount, setReceivedAmount] = useState(null);
  const retryRef = useRef(false);
  const [showFailedPayment, setShowFailedPayment] = useState(false);

  const [noRetry, setNoRetry] = useState(0);
  const [showFailedToDetectPayment, setShowFailedToDetectPayment] =
    useState(false);
  const purchaseId = purchase?.purchaseId;
  if (!purchaseId) {
    navigation("/");
  }

  useEffect(() => {
    const checkUserAgreementSigned = async () => {
      try {
        const responsePurchase = await getAuthorizedApi(user).purchase();
        setPurchaseDetails(responsePurchase);
        if (responsePurchase.agreementStatus !== "completed") {
          setShowError(true);
        } else {
          const purchaseStr = localStorage.getItem("purchase");
          const purchase = purchaseStr ? JSON.parse(purchaseStr) : null;
          purchase.usd = responsePurchase.usdAmount;
          purchase.amount = responsePurchase.amount;
          purchase.leosAmount = responsePurchase.leosAmount;
          purchase.currency = responsePurchase.currency;
          purchase.receivedAmount = Number(responsePurchase.receivedAmount);
          localStorage.setItem("purchase", JSON.stringify(purchase));
          setAmount(responsePurchase.amount);
          setLeoAmount(responsePurchase.leosAmount);
          setCurrency(responsePurchase.currency);
          setReceivedAmount(Number(responsePurchase.receivedAmount));
          if (
            responsePurchase.paymentStatus === "received" &&
            responsePurchase.transactionStatus === "sent"
          ) {
            // Redirect to Success Page if Payment Received & Token Issued
            purchase.leoAmount = responsePurchase.leosAmount;
            delete purchase.purchaseId;
            delete purchase.leosAmount;
            localStorage.setItem("purchase", JSON.stringify(purchase));
            navigation("/payment-success");
          } else {
            if (responsePurchase.paymentMethod) {
              setPaymentType(responsePurchase.paymentMethod);
              if (responsePurchase.paymentStatus === "created") {
                if(responsePurchase.paymentMethod === 'b2binpay'){
                  setCheckB2BinPayPayment(true);
                  startCheckingPayment(responsePurchase.deposit);
                }
              } else if (responsePurchase.paymentStatus === "incomplete") {
                setShowRepayment(true);
              }
              setShowPaymentLink(true);
              setDeposit(responsePurchase.deposit);
            }
          }
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    };
    if (user) {
      checkUserAgreementSigned();
    }
  }, [user, navigation]);

  async function handleSubmit(e) {
    setShowPaymentLink(true);

    let method = "";
    if (e.target.id === "metamask_payment_button") {
      method = "metamask";
    } else {
      setCheckB2BinPayPayment(true);
      method = "b2binpay";
    }
    setPaymentType(method);
    if (deposit === null) {
      try {
        const response = await getAuthorizedApi(user).createDeposit(method);
        if (response.status === 2) {
          setDeposit(response);
          if (method !== "metamask") {
            startCheckingPayment(response);
          }
        }
      } catch (e) {
        console.log(e);
        setCheckB2BinPayPayment(false);
        setShowErrorMsg(true);
        setError(e.response.data.message);
      }
    } else {
      startCheckingPayment(deposit);
    }
  }

  const onComplete = () => {
    const checkbox = document.getElementById("metamask_payment_button");
    checkbox.checked = false;
    setPaymentType(null);
  };

  const handleNeedHelp = (e) => {
    e.preventDefault();
    setShowFailedToDetectPayment(false);
    setShowHelp(true);
  };

  const checkPayment = useCallback(async () => {
    try {
      const responsePurchase = await getAuthorizedApi(user).purchase();
      let stopChecking = false;
      if (
        Number(responsePurchase.receivedAmount) >=
        Number(responsePurchase.amount)
      ) {
        retryRef.current = false;
      }

      if (responsePurchase.paymentStatus === "received" && !retryRef.current) {
        // Check Payment Received or not
        // redirect to success page
        stopChecking = true;
        retryRef.current = false;
        purchase.receivedAmount = Number(responsePurchase.receivedAmount);
        purchase.leoAmount = responsePurchase.leosAmount;
        purchase.amount = responsePurchase.amount;
        purchase.usd = responsePurchase.usdAmount;
        delete purchase.purchaseId;
        delete purchase.leosAmount;
        localStorage.setItem("purchase", JSON.stringify(purchase));
        navigation("/payment-success");
      } else if (
        responsePurchase.paymentStatus === "incomplete" &&
        !retryRef.current
      ) {
        // show the paymentPage link for repayment for incomplete
        stopChecking = true;
        retryRef.current = false;
        setAmount(responsePurchase.amount);
        setReceivedAmount(Number(responsePurchase.receivedAmount));
        setShowRepayment(true);
      }

      if (stopChecking) {
        clearInterval(intervalIdRef.current);
        clearTimeout(timeoutIdRef.current);
        setCheckB2BinPayPayment(false);
      }
    } catch (e) {
      console.log(e);
      setCheckB2BinPayPayment(false);
      setShowErrorMsg(true);
      setError(e.response.data.message);
    }
  }, [user, purchase, navigation]);

  const startCheckingPayment = (deposit = null, retry = false) => {
    if (noRetry >= 5) {
      // Restrict the user to allow five retries.
      showFailedToDetectPayment(true);
      return;
    }
    retryRef.current = retry;
    setShowRepayment(false);
    setShowFailedPayment(false);
    if (!checkB2BinPayPayment) {
      setTimeout(() => {
        intervalIdRef.current = setInterval(checkPayment, 5000); // Call the API every second
      }, 5000);
      timeoutIdRef.current = setTimeout(() => {
        clearInterval(intervalIdRef.current); // Stop the interval after 5 minutes
        setCheckB2BinPayPayment(false);
        setShowFailedPayment(true);
      }, 600000); // 5 minutes in milliseconds
    }
    setCheckB2BinPayPayment(true);
    if (deposit) {
      window.open(deposit.paymentPage, "_blank");
    }
  };

  const sdkOptions = useMemo(
    () => ({
      logging: { developerMode: false },
      useDeeplink: true,
      checkInstallationImmediately: false,
      checkInstallationOnAllCalls: false,
      dappMetadata: {
        name: "Pangea",
        url: `${window.location.origin}/onboarding/start`,
      },
      infuraAPIKey: settings.config.infuraAPIKey,
    }),
    []
  );

  return (
    <>
      <NeedHelpModal open={showHelp} onClose={setShowHelp} />

      <Modal isOpen={showErrorMsg} onClose={() => setShowErrorMsg(false)}>
        <Modal.Header>
          <h3 className="model-title">Server Error</h3>
        </Modal.Header>
        <Modal.Body>
          <p className="mt-2">{error}</p>
          <button
            onClick={() => setShowErrorMsg(false)}
            className="btn btn-lg btn-secondary w-100 mt-4"
          >
            <span>Please try again</span>
          </button>
        </Modal.Body>
      </Modal>

      <Modal isOpen={showError} onClose={() => setShowError(false)}>
        <Modal.Header>
          <h3 className="model-title">Token Sale Agreement Required</h3>
        </Modal.Header>
        <Modal.Body>
          <p>
            We regret to inform you that your token sale agreement requires a
            signature to proceed with identity verification. Without this
            crucial step, regulatory compliance cannot be ensured, risking
            closure of Tonomy Foundation's bank accounts. Your cooperation is
            essential to continue accessing LEOS services and support the
            development of Pangea.
          </p>
          <button
            onClick={() => navigation("/onboarding/token-sale-agreement")}
            className="btn btn-lg btn-danger w-100 mt-4"
          >
            <span>Go to Token Sale Agreement</span>
          </button>
        </Modal.Body>
      </Modal>

      <Modal isOpen={checkB2BinPayPayment}>
        <Modal.Body header={false}>
          <div className="d-flex flex-column justify-content-center align-items-center gap-3">
            <div>
              <div className="waiting-loader mb-1"></div>
            </div>
            {!deposit ? (
              <h3 className="mb-0">Creating your deposit address</h3>
            ) : (
              <h3 className="mb-0">We're checking your payment</h3>
            )}
            {deposit && (
              <p className="text-center">
                Please pay{" "}
                {Number(receivedAmount) === 0 ? (
                  <span className="fw-bold">
                    {" "}
                    exactly {`${lAmount} ${lCurrency}`}{" "}
                  </span>
                ) : (
                  <span className="fw-bold">
                    {" "}
                    exactly{" "}
                    {`${(Number(amount) - Number(receivedAmount)).toFixed(
                      4
                    )} ${lCurrency}`}{" "}
                  </span>
                )}{" "}
                to the deposit address on Coinsbuy
              </p>
            )}
            {deposit && (
              <a
                href={deposit.paymentPage}
                target="_blank"
                className="btn btn-link"
              >
                Go to payment page
              </a>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal isOpen={showFailedPayment}>
        <Modal.Body header={false}>
          <div className="d-flex flex-column justify-content-center align-items-center gap-3">
            <Info className="mb-1" />
            <h3 className="mb-0">Your transaction hasn't been detected yet</h3>
            <p className="text-center">Please wait some time and try again</p>
            <button
              onClick={() => {
                setNoRetry((prevCount) => prevCount + 1);
                startCheckingPayment(deposit, true);
              }}
              className="btn btn-lg btn-outline-dark px-5"
            >
              <span>Try again</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal isOpen={showRepayment}>
        <Modal.Body header={false}>
          <div className="d-flex flex-column justify-content-center align-items-center gap-3">
            <Info className="mb-1" />
            <h3 className="mb-0">
              We've received {receivedAmount} ETH out of {amount} ETH
            </h3>
            <p className="text-center">
              Please add {(Number(amount) - Number(receivedAmount)).toFixed(4)}{" "}
              ETH to complete the payment process
            </p>
            <button
              onClick={() => {
                setNoRetry((prevCount) => prevCount + 1);
                startCheckingPayment(deposit, true);
              }}
              className="btn btn-lg btn-outline-dark px-5"
            >
              <span>Proceed</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal isOpen={showFailedToDetectPayment}>
        <Modal.Body header={false}>
          <div className="d-flex flex-column justify-content-center align-items-center gap-3">
            <ErrorX className="mb-1" />
            <h3 className="mb-0">We haven't received your payment</h3>
            <p className="text-center">
              Please contact support or check your transaction on ethereum block
              explorer
            </p>
            <button
              onClick={handleNeedHelp}
              className="btn btn-lg btn-outline-dark px-5"
            >
              <span>Call for support</span>
            </button>
            {deposit?.address && (
              <a
                href={`${settings.config.ethereumNetwork}/address/${deposit?.address}`}
                target="_blank"
                className="btn btn-link"
              >
                Check the block explorer
              </a>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <div className="position-relative payment-step-block">
        <div className="payment-step-box d-flex flex-column">
          <ul className="amount-listing d-flex flex-column m-0 p-0">
            <li className="d-flex justify-content-between align-items-center">
              <span className="label">Amount</span>
              <p className="price number-text">{`${amount} ${currency}`}</p>
            </li>
            <li className="d-flex justify-content-between align-items-center">
              <span className="label">Total LEOS</span>
              <p className="price number-text">{`${leoAmount}Ƚ`}</p>
            </li>
          </ul>
          <div className="payment-option-bar d-flex flex-column gap-3">
            <span className="heading">Select Payment option</span>
            <div className="payment-option-bar d-flex flex-md-row flex-column gap-md-3 gap-2">
              {purchaseDetails && (
                <div className="payment-option-btn radio-box flex-grow-1 label-radio-btn d-flex flex-column gap-2">
                  <div className="form-check mb-0 d-flex align-items-center ps-0 gap-2 w-100 ">
                    <input
                      className="form-check-input m-0"
                      type="radio"
                      name="Nationality-confirm-btn"
                      value="metamask"
                      id="metamask_payment_button"
                      onClick={handleSubmit}
                      defaultChecked={paymentType === "metamask"}
                      disabled={deposit}
                    />
                    <label
                      className="form-check-label w-100 position-relative"
                      htmlFor="metamask_payment_button"
                    >
                      <div className="label-caption d-flex align-items-center justify-content-center text-center gap-2">
                        <div className="icon-bar">
                          <img src={MetamaskIcon} height={24} alt="" />
                        </div>
                        <span>Pay with Metamask</span>
                        <div className="checked-icon">
                          <CheckBlue />
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="form-check mb-0 d-flex align-items-center ps-0 gap-2 w-100">
                    <input
                      className="form-check-input m-0"
                      type="radio"
                      name="Nationality-confirm-btn"
                      value="b2binpay"
                      id="other_wallets"
                      defaultChecked={paymentType === "b2binpay"}
                      onClick={handleSubmit}
                    />
                    <label
                      className="form-check-label w-100 position-relative"
                      htmlFor="other_wallets"
                    >
                      <div className="label-caption d-flex align-items-center justify-content-center text-center gap-2">
                        <div className="d-flex gap-3">
                          <span>Pay with other wallets</span>
                        </div>
                        <div className="checked-icon">
                          <CheckBlue />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              )}
              <div className="payment-option-info d-none">
                <ul className="info-payemnt-listing d-flex flex-column gap-1 m-0 p-0">
                  <li>Credit Card (2.9% fee)</li>
                  <li>Ethereum (0%)</li>
                  <li>Direct Debit (0.2%)</li>
                  <li>SEPA Transfer (80c)</li>
                </ul>
              </div>
            </div>

            <MetaMaskUIProvider debug={false} sdkOptions={sdkOptions}>
              {paymentType === "metamask" && !checkB2BinPayPayment && (
                <Metamask
                  amount={amount}
                  currency={currency}
                  onComplete={onComplete}
                  startCheckingPayment={startCheckingPayment}
                  deposit={deposit}
                  handleNeedHelp={handleNeedHelp}
                />
              )}
            </MetaMaskUIProvider>
          </div>
        </div>
        {loading === true && (
          <div className="loader-container">
            <span className="loader"></span>
          </div>
        )}
      </div>
    </>
  );
}
